import React from 'react'
import { Heading, Paragraph } from 'grommet'

import { Layout } from '../layout'
import SEO from '../components/seo'

const DidacticPage = () => (
  <Layout>
    <SEO title="Dydaktyka" />
    <Heading>Dydaktyka</Heading>
    <Paragraph fill>
      Pracownicy Katedry Nanobiotechnologii prowadzą zajęcia dydaktyczne dla
      kierunków takich jak Bioinżynieria Zwierząt, Hodowla i Ochrona Zwierząt
      Towarzyszących i Dzikich, Zootechnika oraz Biologia, a także dla studentów
      biorących udział w programie ERASMUS.
    </Paragraph>
    <Heading level={4}>Prowadzone przedmioty:</Heading>
    <ul>
      <li>Analiza bioobrazowania</li>
      <li>Analiza instrumentalna</li>
      <li>Badania podstawowe i przedkliniczne biomolekuł</li>
      <li>Biologia komórki nowotworowej</li>
      <li>Biomateriały i Bionika</li>
      <li>Cytofizjologia</li>
      <li>Fizjologia Procaryota</li>
      <li>Hodowle in vitro</li>
      <li>Inżynieria biomolekuł</li>
      <li>Inżynieria nanobiotechnologiczna</li>
      <li>Inżynieria tkankowa</li>
      <li>Kancerogeneza</li>
      <li>Komórki macierzyste</li>
      <li>Kot - hodowla i utrzymanie</li>
      <li>Metabolomika zwierząt</li>
      <li>Mikrobiologia</li>
      <li>Modele biologiczne in vitro</li>
      <li>Myślenie projektowe</li>
      <li>Odżywianie funkcjonalne</li>
      <li>Podstawy mikrobiologii</li>
      <li>Podstawy nanobiotechnologii</li>
      <li>Procesy śmierci komórek</li>
      <li>Projektowanie, konstrukcja i zastosowanie nanobiosystemów</li>
      <li>Systemy jakości</li>
      <li>Technologie fermentacyjne</li>
      <li>Technologie liposomowe</li>

      <li>Żywienie zwierząt drapieżnych</li>
      <li>Żywienie zwierząt roślinożernych i wszystkożernych</li>
    </ul>
  </Layout>
)

export default DidacticPage
